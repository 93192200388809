<template>
	<div class="main1">
		<div class="left">
			<div class="top">
				<img v-if="$store.state.myInfo.headimg == ''" class="avatar" src="https://fuhuiduoduo.axa2.com/upload/static/icon-touxiang@2x(1).png" alt="">
				<img v-if="$store.state.myInfo.headimg != ''" class="avatar" :src="$store.state.myInfo.image" alt="">
				<div class="name">{{$store.state.myInfo.name}}</div>
				<div v-if="$store.state.identity == 1" class="label">{{ $t('user')}}</div>
				<div v-if="$store.state.identity == 2" class="label">{{ $t('isenterprise')}}</div>
				<div v-if="$store.state.identity == 3" class="label">{{ $t('producer')}}</div>
				<div v-if="$store.state.identity != 2" class="button" @click="uploadWork">{{ $t('uploadingworks') }}</div>
			</div>
			<div class="bottom">
				<el-menu router :default-active="sideActive" class="el-menu-demo" mode="horizontal"
					@select="handleSelect">
					<el-menu-item v-for="(item,index) in tabsListMenu" :index="item.url" :key="index">
						{{item.name}}
					</el-menu-item>
				</el-menu>
			</div>
		</div>
		<div class="right">
			<!-- <div class="nav-lf">
			  <MusicType
			    :width="952"
			    :type="type"
			    :style="{ paddingLeft: num + 'px' }"
			    :tabsList="tabsList"
			    :dropdownList="dropdownList"
			  ></MusicType>
			</div> -->
			<router-view></router-view>
		</div>
	</div>
</template>

<script>
	import MusicType from "@/components/content/MusicType.vue";
	import { getUserInfo } from "@/utils/common/user";
	import {
		myInfo
	} from "@/api/Mine";

	export default {
		props: {},
		data() {
			return {
				num: '',
				type: '',
				infoObj: {},//我的信息
				tabsListMenu: [],
				tabsListUser: [{
						name:  `${this.$t('mywallet')}`,
						url: '/personalCenter/myWallet/accountBalance'
					},
					// {
					// 	name:  `${this.$t('myworks')}`,
					// 	url: '/personalCenter/myWork/workproducthmusic'
					// },
					{
						name:  `${this.$t('myinterest')}`,
						url: '/personalCenter/myAttention'
					},
					{
						name:  `${this.$t('myfavorite')}`,
						url: '/personalCenter/myCollection/collectMusic'
					},
					{
						name: `${this.$t('teammanagement')}`,
						url: '/personalCenter/teamManagement/createTeam'
					},
					// {
					// 	name:  `${this.$t('myclaim')}`,
					// 	url: '/personalCenter/myClaim/complete'
					// },
					{
						name:  `${this.$t('mypurchase')}`,
						url: '/personalCenter/MyPurchase/Purchased'
					},
					// {
					// 	name:  `${this.$t('mycustom')}`,
					// 	url: '/personalCenter/MyCustomization/CustomUnfinished'
					// },
					// {
					// 	name: `${this.$t('joinorganization')}`,
					// 	url: '/personalCenter/joinOrganization/invitationCode'
					// },
					{
						name:  `${this.$t('AccountKeeper')}`,
						url: '/personalCenter/accountManagement/personInformation'
					}
				],
				tabsListUser1: [{
						name:  `${this.$t('mywallet')}`,
						url: '/personalCenter/myWallet/accountBalance'
					},
					// {
					// 	name:  `${this.$t('myworks')}`,
					// 	url: '/personalCenter/myWork/workproducthmusic'
					// },
					{
						name:  `${this.$t('myinterest')}`,
						url: '/personalCenter/myAttention'
					},
					{
						name:  `${this.$t('myfavorite')}`,
						url: '/personalCenter/myCollection/collectMusic'
					},
					// {
					// 	name: `${this.$t('teammanagement')}`,
					// 	url: '/personalCenter/teamManagement/createTeam'
					// },
					// {
					// 	name:  `${this.$t('myclaim')}`,
					// 	url: '/personalCenter/myClaim/complete'
					// },
					{
						name:  `${this.$t('mypurchase')}`,
						url: '/personalCenter/MyPurchase/Purchased'
					},
					// {
					// 	name:  `${this.$t('mycustom')}`,
					// 	url: '/personalCenter/MyCustomization/CustomUnfinished'
					// },
					{
						name: `${this.$t('joinorganization')}`,
						url: '/personalCenter/joinOrganization/invitationCode'
					},
					{
						name:  `${this.$t('AccountKeeper')}`,
						url: '/personalCenter/accountManagement/personInformation'
					}
				],
				// tabsListUser1: [{
				// 		name:  `${this.$t('mywallet')}`,
				// 		url: '/personalCenter/myWallet/accountBalance'
				// 	},
				// 	// {
				// 	// 	name:  `${this.$t('myworks')}`,
				// 	// 	url: '/personalCenter/myWork/workproducthmusic'
				// 	// },
				// 	{
				// 		name:  `${this.$t('myinterest')}`,
				// 		url: '/personalCenter/myAttention'
				// 	},
				// 	{
				// 		name:  `${this.$t('myfavorite')}`,
				// 		url: '/personalCenter/myCollection/collectMusic'
				// 	},
				// 	// {
				// 	// 	name:  `${this.$t('myclaim')}`,
				// 	// 	url: '/personalCenter/myClaim/complete'
				// 	// },
				// 	{
				// 		name:  `${this.$t('mypurchase')}`,
				// 		url: '/personalCenter/MyPurchase/Purchased'
				// 	},
				// 	// {
				// 	// 	name:  `${this.$t('mycustom')}`,
				// 	// 	url: '/personalCenter/MyCustomization/CustomUnfinished'
				// 	// },
				// 	// {
				// 	// 	name: `${this.$t('joinorganization')}`,
				// 	// 	url: '/personalCenter/joinOrganization/invitationCode'
				// 	// },
				// 	{
				// 		name:  `${this.$t('AccountKeeper')}`,
				// 		url: '/personalCenter/accountManagement/personInformation'
				// 	}
				// ],
				// tabs数据
				tabsListMusic: [{
						name:  `${this.$t('mywallet')}`,
						url: '/personalCenter/myWallet/accountBalance'
					},
					{
						name:  `${this.$t('myworks')}`,
						url: '/personalCenter/myWork/workproducthmusic'
					},
					{
						name:  `${this.$t('myinterest')}`,
						url: '/personalCenter/myAttention'
					},
					{
						name:  `${this.$t('myfavorite')}`,
						url: '/personalCenter/myCollection/collectMusic'
					},
					{
						name:  `${this.$t('myclaim')}`,
						url: '/personalCenter/myClaim/complete'
					},
					// {
					// 	name:  `${this.$t('mypurchase')}`,
					// 	url: '/personalCenter/MyPurchase/Purchased'
					// },
					// {
					// 	name:  `${this.$t('mycustom')}`,
					// 	url: '/personalCenter/MyCustomization/CustomUnfinished'
					// },
					{
						name: `${this.$t('teammanagement')}`,
						url: '/personalCenter/teamManagement/createTeam'
					},
					{
						name: `${this.$t('joinorganization')}`,
						url: '/personalCenter/joinOrganization/invitationCode'
					},
					{
						name:  `${this.$t('AccountKeeper')}`,
						url: '/personalCenter/accountManagement/personInformation'
					}
				],
				tabsListMusic1: [{
						name:  `${this.$t('mywallet')}`,
						url: '/personalCenter/myWallet/accountBalance'
					},
					{
						name:  `${this.$t('myworks')}`,
						url: '/personalCenter/myWork/workproducthmusic'
					},
					{
						name:  `${this.$t('myinterest')}`,
						url: '/personalCenter/myAttention'
					},
					{
						name:  `${this.$t('myfavorite')}`,
						url: '/personalCenter/myCollection/collectMusic'
					},
					{
						name:  `${this.$t('myclaim')}`,
						url: '/personalCenter/myClaim/complete'
					},
					// {
					// 	name:  `${this.$t('mypurchase')}`,
					// 	url: '/personalCenter/MyPurchase/Purchased'
					// },
					// {
					// 	name:  `${this.$t('mycustom')}`,
					// 	url: '/personalCenter/MyCustomization/CustomUnfinished'
					// },
					{
						name: `${this.$t('teammanagement')}`,
						url: '/personalCenter/teamManagement/createTeam'
					},
					// {
					// 	name: '加入组织',
					// 	url: '/personalCenter/joinOrganization/invitationCode'
					// },
					{
						name:  `${this.$t('AccountKeeper')}`,
						url: '/personalCenter/accountManagement/personInformation'
					}
				],
				tabsList: [{
						name:  `${this.$t('mywallet')}`,
						url: '/personalCenter/myWallet/accountBalance'
					},
					{
						name:  `${this.$t('myinterest')}`,
						url: '/personalCenter/myAttention'
					},
					{
						name:  `${this.$t('myfavorite')}`,
						url: '/personalCenter/myCollection/collectMusic'
					},
					{
						name:  `${this.$t('mypurchase')}`,
						url: '/personalCenter/MyPurchase/Purchased'
					},
					{
						name:  `${this.$t('mycustom')}`,
						url: '/personalCenter/MyCustomization/CustomUnfinished'
					},
					{
						name: `${this.$t('businessmanagement')}`,
						url: '/personalCenter/enterpriseManagement/collectWorks'
					},
					
					// {
					// 	name: '加入组织',
					// 	url: '/personalCenter/joinOrganization/invitationCode'
					// },
					{
						name:  `${this.$t('AccountKeeper')}`,
						url: '/personalCenter/accountManagement/personInformation'
					}
				],
				tabsList1: [{
						name:  `${this.$t('mywallet')}`,
						url: '/personalCenter/myWallet/accountBalance'
					},
					{
						name:  `${this.$t('myinterest')}`,
						url: '/personalCenter/myAttention'
					},
					{
						name:  `${this.$t('myfavorite')}`,
						url: '/personalCenter/myCollection/collectMusic'
					},
					{
						name:  `${this.$t('mypurchase')}`,
						url: '/personalCenter/MyPurchase/Purchased'
					},
					{
						name:  `${this.$t('mycustom')}`,
						url: '/personalCenter/MyCustomization/CustomUnfinished'
					},
					// {
					// 	name: '加入组织',
					// 	url: '/personalCenter/joinOrganization/invitationCode'
					// },
					{
						name:  `${this.$t('AccountKeeper')}`,
						url: '/personalCenter/accountManagement/personInformation'
					}
				],
				// 全部下来菜单
				// dropdownList: [
				//   {
				//     name: "全部",
				//   },
				//   {
				//     name: "分类1",
				//   },
				//   {
				//     name: "分类2",
				//   },
				// ],
				sideActive: '/myWallet',
				group_type: '',
			}
		},
		computed: {},
		components: {
			// MusicType
		},
		watch: {
			"$store.state.myInfo": {
				handler(newValue, oldValue) {
					console.log("myInfomyInfo变化:", newValue);
					// let searchValue = [];
					// let suitArr = this.$store.state.tagsList.suit_arr;
					// for (let i = 0; i < newValue.length; i++) {
					// 	for (const key in suitArr) {
					// 		if (newValue[i] == suitArr[key]) {
					// 			searchValue.push(key);
					// 		}
					// 	}
					// }
					// this.params.suit = searchValue.join(",");
					// this.TopMusicList(true);
				},
			},
			"$store.state.groupType": {
				handler(newValue, oldValue) {
					console.log("groupType变化:", newValue);
					if(this.$store.state.identity == 3 && newValue == '2'){
						this.tabsListMenu = this.tabsListMusic1
					}else if(this.$store.state.identity == 3 && newValue == '0'){
						this.tabsListMenu = this.tabsListMusic
					}else if(this.$store.state.identity == 1 && newValue == '0'){
						this.tabsListMenu = this.tabsListUser1
					}else if(this.$store.state.identity == 1 && newValue == '1'){
						this.tabsListMenu = this.tabsListUser
					}else if(this.$store.state.identity == 2 && newValue == '1'){
						this.tabsListMenu = this.tabsList
					}
					
				},
			},
		},
		methods: {
			getUserInfo,
			uploadWork(){
				console.log(this.$store.state.identity,'////////////////');
				if(this.$store.state.identity == 3){
					console.log('kalalalalalal')
					this.$router.push({
						path: '/uploadopus/finishmusic'
					})
				}else{
					console.log('aaaaaaaaaaa')
					this.$router.push({
						path: '/uploadopus/musiciancertification'
					})
					
				}
			},
			handleSelect(key) {
				console.log(key,'key')
				this.sideActive = key
			},
			// 我的信息
			getMyInfo(){
				myInfo({}).then((res) => {
            console.log('我的-左侧-我的信息成功', res);
					  this.infoObj = res.data
					  // if(this.infoObj.identity == 2){
						 //  this.tabsListMenu = this.tabsListEnterprise
					  // }else if(this.infoObj.identity == 3){
						 //  this.tabsListMenu = this.tabsListMember
					  // }
					  this.$store.state.identity = res.data.identity
					  this.$store.state.isBoss = res.data.is_boss
					  this.$store.state.bankList = res.data.bankcard
					  this.$store.state.groupType = res.data.group_type
					  localStorage.setItem('myInfo', JSON.stringify(this.infoObj))
					  if(this.$store.state.identity == 2){
					  	this.tabsListMenu = this.tabsList
					  	
					  }else if(this.$store.state.identity == 3){
					  	if(this.$store.state.groupType == 0) {
							console.log('111111111111111111111')
					  		// console.log(this.$store.state.groupType,'this.group_typethis.group_type')
					  		this.tabsListMenu = this.tabsListMusic
					  	}else {
					  		this.tabsListMenu = this.tabsListMusic1
							console.log('22222222222222222')
					  	}
					  }else if(this.$store.state.identity == 1) {
					  	if(this.$store.state.groupType == 0) {
					  		this.tabsListMenu = this.tabsListUser1
							console.log('33333333333333333333')
					  	}else if(this.$store.state.groupType == 2) {
					  		this.tabsListMenu = this.tabsListUser
							console.log('44444444444')
					  	}
					  	
					  }
				    })
				    .catch((err) => {
				      console.log(err,'我的信息失败');
				    });
			}
		},
		created() {
			this.getMyInfo()
			let userInfo = JSON.parse(this.getUserInfo())
			console.log(this.$store.state.identity,userInfo,'userInfo')
			// this.$store.state.identity = userInfo.identity
			// this.$store.state.isBoss = userInfo.is_boss
			// this.group_type = userInfo.group_type
			console.log(userInfo,this.$store.state.identity,this.$store.state.isBoss,'222222222')
			
			
		},
		mounted() {
			console.log(this.$route)
			this.sideActive = this.$route.path
		}

	}
</script>

<style lang="less" scoped>
	.main1 {

		color: @recommendMusicianListTextColor;
		display: flex;
		flex: 1 auto;
		width: 100%;

		.left {
			width: 220px;
			// height: 249px;

			border-radius: 6px;
			display: flex;
			flex-direction: column;
			align-items: center;
			// justify-content: center;

			// backdrop-filter: blur(50px);
			.top {
				height: 249px;
				width: 220px;
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
				background: @recommendMusicianListBGColor;
				padding-bottom: 20px;
				border-radius: 6px;

				.avatar {
					margin: 16px 0;
					width: 70px;
					height: 70px;
					border-radius: 50%;
				}

				.name {
					margin-bottom: 4px;
					font-size: 16px;
					font-weight: 500;
					color: @headerSelectTextColor;
				}

				.label {
					font-size: 12px;
					color: #999999;
				}

				.button {
					width: 172px;
					height: 38px;
					background: #3370FF;
					border-radius: 6px;
					display: flex;
					align-items: center;
					justify-content: center;
					font-size: 16px;
					font-weight: 600;
					color: #FFFFFF;
					margin-top: 24px;
					cursor: pointer;
				}
			}

			.bottom {
				background: @recommendMusicianListBGColor;
				margin-top: 28px;
				// height: 100%;
				padding: 20px 0;
				display: flex;
				flex-direction: column;
				width: 100%;
				border-radius: 6px;

				::v-deep .el-menu {
					background: rgba(255, 255, 255, 0);
					display: flex;
					flex-direction: column;
					width: 100%;

					&.el-menu--horizontal {
						border-bottom: none;
					}
				}

				// el-menu-demo el-menu--horizontal el-menu

				::v-deep .el-menu-item {
					border-bottom: none;
					text-decoration: none;
					list-style: none;
					font-size: 16px;
					font-weight: 400 !important;
					color: #999999 !important;

					&.is-active {
						background: rgba(255, 255, 255, 0);
						color: @headerSelectTextColor  !important;
						font-size: 16px;
						font-weight: 600 !important;
						border-left: 4px solid #3370FF;
					}

					&:hover {
						background: rgba(255, 255, 255, 0);
						color: @headerSelectTextColor  !important;
						font-size: 16px;
						font-weight: 600 !important;
					}
				}
			}
		}

		.right {
			width: 100%;

			.nav-lf {
				margin-left: 28px;
			}
		}
	}
</style>
